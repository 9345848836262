export default {
	header: {
		self: {},
		items: [
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Lojas",
				root: true,
				icon: "fas fa-store",
				page: "loja",
				bullet: "dot"
			},
			{
				title: "Modelos",
				root: true,
				icon: "fas fa-list",
				page: "modelo",
				bullet: "dot"
			},
			{
				title: "Relatórios",
				root: true,
				bullet: "dot",
				icon: "fas fa-chart-pie",
				submenu: [
					{
						title: "Relatório 01",
						bullet: "dot",
						page: "relatorio/relatorio01"
					},
					{
						title: "Relatório 02",
						bullet: "dot",
						page: "relatorio/relatorio02"
					},
				]
			},
			{
				title: "Administradores",
				root: true,
				icon: "fas fa-user-shield",
				page: "admin",
				bullet: "dot"
			},
			{
				title: "Grupos de Acesso",
				root: true,
				icon: "fas fa-layer-group",
				page: "group",
				bullet: "dot"
			},
		]
	}
};
